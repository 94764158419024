import useObjectState from "../hooks/UseObjectState";
import {cloneElement} from "react";

function isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

const ObjectStateWrapper = ({stateArray, index, children, prop = "state", deletable = false, debug = false}: { stateArray: *, index: (string | number), children: *, prop: string, deletable: boolean, debug: boolean }) => {
    const state = useObjectState(stateArray, index, deletable, debug);
    if (isFunction(children)) {
        return children(state);
    }
    const props = {};
    props[prop] = state;
    return cloneElement(children, props);
}

export default ObjectStateWrapper;
