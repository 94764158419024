import {forwardRef} from "react";

interface FormFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
    getter: string;
    setter: CallableFunction;
    error: string;
}

const FormField: React.FC<FormFieldProps> = forwardRef(({ getter, setter, error = "", ...props }, ref) => {
    if (error) {
        props.className += " is-invalid";
    }
    return <>
        <input ref={ref} {...props} value={getter} onChange={event => setter(event.target.value)}/>
        {error && <div className="invalid-feedback">{error}</div>}
    </>
});

export default FormField;
