import FormField from "../../components/FormField";
import {useNavigate} from "react-router";
import {useState} from "react";
import {Auth} from "aws-amplify";

const AuthConfirmScreen = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState(localStorage.email || "");
    const [code, setCode] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const enabled = /^[^@]+@[^.]+\..+$/.test(email) && /\d{6}/.test(code) && !loading;

    const confirm = () => {
        setError("");
        setLoading(true);
        Auth.confirmSignUp(email, code).then(() => {
            navigate("/");
        }).catch((e) => {
            setError(e.name); // CodeMismatchException and NotAuthorizedException
        }).finally(() => {
            setLoading(false);
        })
    }

    return <div className="position-absolute translate-middle start-50 top-50">
        <h1 className="mb-5 ms-1 text-danger user-select-none font-fantasy">Comprehend</h1>
        <div className="p-4 p-lg-5 shadow-lg rounded-3 bg-white">
            <h3 className="mb-5 me-lg-5">Confirm your Comprehend account</h3>

            <div className="mb-4">
                <label className="form-label fw-semibold">Email</label>
                <FormField type="text" className="form-control" getter={email} setter={setEmail} error={error === "NotAuthorizedException" ? "Invalid!" : ""}/>
            </div>

            <div className="mb-5">
                <label className="form-label fw-semibold">Code</label>
                <FormField autoFocus={true} type="text" className="form-control" getter={code} setter={setCode} error={error === "CodeMismatchException" ? "Wrong code!": ""}/>
            </div>

            <button className="btn btn-primary w-100" onClick={confirm} disabled={!enabled}>{loading ? <span className="spinner-border spinner-border-sm" role="status"></span> : "Continue"}</button>
        </div>
    </div>
}

export default AuthConfirmScreen;
