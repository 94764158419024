import useUser from "../../hooks/UseUser";
import {Link} from "react-router-dom";
import Navbar from "../../components/Navbar";

const StudentHomeScreen = () => {
    const user = useUser();

    return <>
        <Navbar id={"headerNav"} title={"Comprehend"} className={"mb-1"} secondaryTitle={"Student"}>
            <Link className="nav-link" to={user ? "/auth/logout" : "/auth"}>{user ? "Logout": "Login"}</Link>
        </Navbar>
        <div className="container">
            <div></div>
        </div>
    </>
}

export default StudentHomeScreen;
