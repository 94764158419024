import {useState} from "react";
import FormField from "../../../components/FormField";

const AdminEditableText = ({state, tr = (text) => <h5>{text}</h5>}) => {
    const [text, setText] = state;
    const [editing, setEditing] = useState(false);
    const [hovered, setHovered] = useState(false);
    if (!editing) {
        return <div className="d-flex flex-row w-fit-content" onMouseOver={() => {setHovered(true)}} onMouseOut={() => {setHovered(false)}}>
            {tr(text)}
            {hovered && <span className="pointer ms-2" onClick={() => {setEditing(true); setHovered(false)}}><i className="bi pencil"></i></span>}
        </div>
    }
    return <>
        <FormField autoFocus={true} onBlur={() => {setEditing(false)}} type="text" className="form-control form-control-sm w-fit-content" getter={text} setter={setText}/>
    </>
}

export default AdminEditableText;
