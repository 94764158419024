import AdminStaticMultipleChoiceQuestion from "./AdminStaticMultipleChoiceQuestion";
import AdminStaticOrderChoicesQuestion from "./AdminStaticOrderChoicesQuestion";
import AdminStaticMatchChoicesQuestion from "./AdminStaticMatchChoicesQuestion";
import {createElement, useState} from "react";
import {AdminDynamicMultipleChoiceQuestionList} from "./AdminDynamicMultipleChoiceQuestion";
import useObjectState from "../../../../hooks/UseObjectState";
import Hoverable from "../../../../components/Hoverable";

const TITLES = {
    "freeform": "Answer the following questions about the passage.",
    "mainidea": "Which is the main idea of the passage?",
    "authorspurpose": "Which author's purpose is suggested by the text?",
    "orderevents": "Put the following events in the correct order.",
    "causeandeffect": "Match the causes with their effects.",
    "problemandsolution": "Match the problems with their solutions."
}

const RENDERERS = {
    "freeform": AdminDynamicMultipleChoiceQuestionList,
    "mainidea": AdminStaticMultipleChoiceQuestion,
    "authorspurpose": AdminStaticMultipleChoiceQuestion,
    "orderevents": AdminStaticOrderChoicesQuestion,
    "causeandeffect": AdminStaticMatchChoicesQuestion,
    "problemandsolution": AdminStaticMatchChoicesQuestion
}

const QUESTIONS = Object.keys(RENDERERS);

const getRendererForQuestion = (state, index) => {
    return createElement(RENDERERS[index], {state: state, key: index});
}

const AdminQuestion = ({state, index, className}) => {
    const s = useObjectState(state, index);
    /*const [enabled, setEnabled] = useObjectState(s, "enabled", undefined, false, true);*/
    const [hovered, setHovered] = useState(false);

    // TODO fix keywords
    // TODO add disabling
    // TODO fix deleting

    return <div className={className}>
        <Hoverable setter={setHovered}>
            <div className="d-flex flex-row w-fit-content">
                <h5>{TITLES[index]}</h5>
                {/*<div className="form-check form-switch ms-2">
                    <input className="form-check-input" type="checkbox" value="hi" checked={enabled} onChange={(evt) => {setEnabled(evt.target.checked)}}/>
                </div>*/}
                {hovered && <>
                    <span className="pointer ms-2" onClick={() => {console.log("regenerating...")}}><i className="bi bi-arrow-repeat"></i></span>
                </>}
            </div>
        </Hoverable>
        {getRendererForQuestion(s, index)}
    </div>
}

export default AdminQuestion;
export {QUESTIONS, TITLES};
