import {useLayoutEffect, useState} from "react";
import Multiple from 'multiple.js';

const useMultiple = (selector: string, bg: string, affectText: boolean = false, opacity: number | boolean = false) => {
    const [multiple, setMultiple] = useState(null);
    useLayoutEffect(() => {
        setMultiple(new Multiple({selector: selector, background: bg, affectText: affectText, opacity: opacity}));
    }, []);
    return multiple;
}

export default useMultiple;
