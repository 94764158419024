import {Link} from "react-router-dom";
import useUser from "../../hooks/UseUser";
import FormField from "../../components/FormField";
import {useEffect, useState} from "react";
import API from "@aws-amplify/api";
import {listPassageInfos} from "../../amplify/graphql/queries";
import useCachedState from "../../hooks/UseCachedState";
import AdminNavbar from "./components/AdminNavbar";
import awsExports from "../../aws-exports";
import AdminCheckbox from "./components/AdminCheckbox";
import {useNavigate} from "react-router";
import assertAdmin from "./util/AssertAdmin";
import Moment from "react-moment";

const AdminLibraryScreen = () => {
    const navigate = useNavigate();

    const user = useUser();
    assertAdmin(user, navigate);

    const [results, setResults] = useCachedState(null, "libraryresults");
    const [history, setHistory] = useCachedState([], "libraryhistory");

    const [showHistory, setShowHistory] = useState(false);

    const [filtering, setFiltering] = useState(false);
    const [search, setSearch] = useState("");
    const unreviewed = useState(false);

    const search_a = (limit = 15) => {
        if (search) {
            const idx = history.indexOf(search);
            if (idx >= 0) {
                history.splice(idx, 1);
            }
            history.unshift(search);
            setHistory(history);
        }

        const filter = {
            keywords: {
                contains: search.toLowerCase()
            }
        };
        if (unreviewed[0]) {
            filter.qc = {eq: false};
        }
        API.graphql({authMode: "API_KEY", authToken: awsExports.aws_appsync_apiKey, query: listPassageInfos, variables: {filter: filter, limit: limit}}).then(r => {
            setResults(r.data.listPassageInfos.items);
        });
    };

    useEffect(() => {
        search_a();
    }, []);

    const search_f = () => {
        console.log('SEARCH')
        search_a();
    }

    useEffect(() => {
        if (search) {
            setShowHistory(true);
        } else {
            setShowHistory(false);
        }
    }, [search]);

    return <>
        <AdminNavbar user={user}/>
        <div className="container">
            <nav className="nav nav-tabs nav-justified w-100 mb-5">
                <Link className="nav-link fw-bolder" to="/admin/create">Create</Link>
                <Link className="nav-link active fw-bolder" to="/admin/library">Library</Link>
            </nav>

            <div className="mb-5">
                <div className="input-group w-100">
                    <button className="btn btn-secondary" onClick={() => {
                        setFiltering(!filtering)
                    }}><i className={"bi " + (filtering ? "bi-x-circle" : "bi-funnel")}></i></button>
                    <FormField placeholder="Search passages" className="form-control" getter={search} setter={setSearch} onBlur={() => {setShowHistory(false)}}/>
                    <button className="btn btn-primary" onClick={search_f}><i className="bi bi-search"></i></button>
                </div>
                <ul className="mb-2 list-group mx-5 rounded-0 rounded-bottom">
                    {search && showHistory && history.filter(h => h.startsWith(search)).map((h, idx) => <li className="list-group-item list-group-item-action d-flex" key={idx} onClick={() => {console.log("C")}}><i className="bi bi-clock-history me-3"/>{h}</li>)}
                </ul>

                {filtering && <div className="ms-3">
                    <AdminCheckbox className="mb-2" label="Unreviewed" state={unreviewed}/>
                </div>}
            </div>

            {(results && results.length) ? <ul className="list-group">
                {results.map(result => <Link className="list-group-item list-group-item-action d-flex flex-column" to={"/admin/edit/" + result.id} key={result.id}>
                    <div className="d-flex flex-row justify-content-between align-items-center mb-1">
                        {result.title}
                        <div>
                            {result.qc || <span className="m-0 badge rounded-pill bg-light text-dark shadow-sm me-1">Requires Review</span>}
                            <span className="m-0 badge rounded-pill bg-info text-light shadow-sm me-1">{result.category}</span>
                            {/*<span className="m-0 badge rounded-pill bg-warning text-dark shadow-sm">{result.genre}</span>*/}
                        </div>
                    </div>
                    <div>
                        <p className="form-text m-0">Updated <Moment date={result.updatedAt} fromNow={true}/></p>
                    </div>
                </Link>)}
            </ul> : <p className="text-muted">No results</p>}
        </div>
    </>
}

export default AdminLibraryScreen;
