import AdminEditableText from "./AdminEditableText";
import ObjectStateWrapper from "../../../components/ObjectStateWrapper";

const AdminEditableAnswerSelect = ({className, correct, choices}) => {
    if (!choices[0]) {
        return <p>ERROR</p>
    }
    return <div className={className}>
        {choices[0].map((choice, index) => <div className="form-check" key={index}>
            <input className="form-check-input pointer" type="radio" checked={index === correct[0]} onChange={() => {correct[1](index)}}/>
            <ObjectStateWrapper stateArray={choices} index={index}>
                <AdminEditableText tr={(text) => <label className="form-check-label pointer" onClick={() => {correct[1](index)}}>{text}</label>}/>
            </ObjectStateWrapper>
        </div>)}
    </div>
}

export default AdminEditableAnswerSelect;
