import {useEffect, useState} from "react";
import {ReactSortable} from "react-sortablejs";
import {nanoid} from "nanoid";

function arraysEqual(a, b) {
    if (a === b) return true;
    if (!a || !b) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]['choice']) return false;
    }
    return true;
}

function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}


const HomeOrderChoicesQuestion = ({question, setCorrect, reviewing}) => {
    let q = [...question];
    delete q.question;
    const [s, setS] = useState(null);

    useEffect(() => {
        setCorrect(arraysEqual(q, s));
    }, [s]);

    useEffect(() => {
        let qq = [...q];
        shuffle(qq);
        qq = qq.map(x => ({choice: x, id: nanoid(8)}));
        setS(qq);
    }, []);

    if (!s) {
        return <></>
    }

    if (reviewing) {
        return q.map(item => (
            <div className="w-100 shadow-sm rounded-3 mb-2 p-2 border border-danger" key={item}>
                <p className="mb-0">{item}</p>
            </div>
        ))
    }

    return <ReactSortable list={s} setList={setS} animation={150} ghostClass="border-success">
        {s.map(item => (
            <div className="w-100 shadow-sm rounded-3 mb-3 p-3 border border-danger pointer" key={item.id}>
                <p className="mb-0">{item.choice}</p>
            </div>
        ))}
    </ReactSortable>
}

export default HomeOrderChoicesQuestion;
