/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPassageInfo = /* GraphQL */ `
  mutation CreatePassageInfo(
    $input: CreatePassageInfoInput!
    $condition: ModelPassageInfoConditionInput
  ) {
    createPassageInfo(input: $input, condition: $condition) {
      id
      passage
      title
      category
      genre
      keywords
      qc
      details {
        items {
          id
          infoID
          grade
          summary
          freeform
          mainidea
          authorspurpose
          causeandeffect
          problemandsolution
          orderevents
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePassageInfo = /* GraphQL */ `
  mutation UpdatePassageInfo(
    $input: UpdatePassageInfoInput!
    $condition: ModelPassageInfoConditionInput
  ) {
    updatePassageInfo(input: $input, condition: $condition) {
      id
      passage
      title
      category
      genre
      keywords
      qc
      details {
        items {
          id
          infoID
          grade
          summary
          freeform
          mainidea
          authorspurpose
          causeandeffect
          problemandsolution
          orderevents
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePassageInfo = /* GraphQL */ `
  mutation DeletePassageInfo(
    $input: DeletePassageInfoInput!
    $condition: ModelPassageInfoConditionInput
  ) {
    deletePassageInfo(input: $input, condition: $condition) {
      id
      passage
      title
      category
      genre
      keywords
      qc
      details {
        items {
          id
          infoID
          grade
          summary
          freeform
          mainidea
          authorspurpose
          causeandeffect
          problemandsolution
          orderevents
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPassageDetails = /* GraphQL */ `
  mutation CreatePassageDetails(
    $input: CreatePassageDetailsInput!
    $condition: ModelPassageDetailsConditionInput
  ) {
    createPassageDetails(input: $input, condition: $condition) {
      id
      infoID
      grade
      summary
      freeform
      mainidea
      authorspurpose
      causeandeffect
      problemandsolution
      orderevents
      createdAt
      updatedAt
    }
  }
`;
export const updatePassageDetails = /* GraphQL */ `
  mutation UpdatePassageDetails(
    $input: UpdatePassageDetailsInput!
    $condition: ModelPassageDetailsConditionInput
  ) {
    updatePassageDetails(input: $input, condition: $condition) {
      id
      infoID
      grade
      summary
      freeform
      mainidea
      authorspurpose
      causeandeffect
      problemandsolution
      orderevents
      createdAt
      updatedAt
    }
  }
`;
export const deletePassageDetails = /* GraphQL */ `
  mutation DeletePassageDetails(
    $input: DeletePassageDetailsInput!
    $condition: ModelPassageDetailsConditionInput
  ) {
    deletePassageDetails(input: $input, condition: $condition) {
      id
      infoID
      grade
      summary
      freeform
      mainidea
      authorspurpose
      causeandeffect
      problemandsolution
      orderevents
      createdAt
      updatedAt
    }
  }
`;
export const createMatch = /* GraphQL */ `
  mutation CreateMatch(
    $input: CreateMatchInput!
    $condition: ModelMatchConditionInput
  ) {
    createMatch(input: $input, condition: $condition) {
      id
      infoID
      playerCount
      players
      createdAt
      updatedAt
    }
  }
`;
export const updateMatch = /* GraphQL */ `
  mutation UpdateMatch(
    $input: UpdateMatchInput!
    $condition: ModelMatchConditionInput
  ) {
    updateMatch(input: $input, condition: $condition) {
      id
      infoID
      playerCount
      players
      createdAt
      updatedAt
    }
  }
`;
export const deleteMatch = /* GraphQL */ `
  mutation DeleteMatch(
    $input: DeleteMatchInput!
    $condition: ModelMatchConditionInput
  ) {
    deleteMatch(input: $input, condition: $condition) {
      id
      infoID
      playerCount
      players
      createdAt
      updatedAt
    }
  }
`;
