import {Link} from "react-router-dom";
import {Auth} from "aws-amplify";
import {useNavigate} from "react-router";
import {useState} from "react";
import FormField from "../../components/FormField";

const AuthLoginScreen = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const enabled = /^[^@]+@[^.]+\..+$/.test(email) && /.{7,18}/.test(password) && !loading;

    /*const granim = useGranim({
        element: '#canvas-basic',
        direction: 'diagonal',
        isPausedWhenNotInView: true,
        states : {
            "default-state": {
                gradients: [
                    ['#f1a678', '#ffffff', '#e78c8b'],
                ]
            }
        },
        customDirection: {
            x0: '0%',
            y0: '100%',
            x1: '100%',
            y1: '0%'
        }
    });*/

    const login = () => {
        setError("");
        setLoading(true);
        Auth.signIn(email, password).then(() => {
            navigate("/");
        }).catch(e => {
            if (e.name === "UserNotConfirmedException") {
                localStorage.setItem("email", email);
                navigate("/auth/confirm")
            } else {
                setError(e.name); // UserNotFoundException or NotAuthorizedException
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    return <>
        <div className="triangle"></div>
        <div className="rectangle-sm shadow-lg"></div>
        <div className="rectangle-lg shadow-lg"></div>
        <div className="position-absolute translate-middle start-50 top-50">
            <h1 className="mb-5 ms-1 text-danger user-select-none font-fantasy">Comprehend</h1>
            <div className="p-4 p-lg-5 shadow-lg rounded-3 bg-white">
                <h3 className="mb-5 me-lg-5">Sign in to your account</h3>

                <div className="mb-4">
                    <label className="form-label fw-semibold">Email</label>
                    <FormField autoFocus={true} type="text" className="form-control" getter={email} setter={setEmail} error={error === "UserNotFoundException" ? "User not found!" : ""}/>
                </div>

                <div className="mb-5">
                    <label className="form-label fw-semibold">Password</label>
                    <FormField type="password" className="form-control" getter={password} setter={setPassword} error={error === "NotAuthorizedException" ? "Incorrect password!" : ""}/>
                </div>

                <button className="btn btn-primary w-100" onClick={login} disabled={!enabled}>{loading ? <span className="spinner-border spinner-border-sm" role="status"></span> : "Continue"}</button>
            </div>
            <p className="small mt-4 mb-2">Don't have an account? <Link className="text-decoration-none" to="/auth/register">Sign up</Link></p>
            <p className="small">Are you a student? <Link className="text-decoration-none" to="/student/login">Login here instead</Link></p>
        </div>
    </>
}

export default AuthLoginScreen;
