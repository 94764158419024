import {useNavigate} from "react-router";
import useUser from "../../hooks/UseUser";
/*import '../../css/bootstrap_vapor.css'*/

/**
 * FEATURES
 * Games by teachers can be seen on student dashboard
 * Fun poppy balloons
 * More!!!
 **/
const StudentJoinQuizScreen = () => {
    const navigate = useNavigate();
    const user = useUser();

    if (user === false) {
        navigate("/auth");
    }

    return <div className="vw-100 min-vh-100 bg-dark d-flex align-items-center justify-content-center overflow-hidden">
        <div className="bg-primary d-flex flex-column align-items-center justify-content-center p-5 rounded-4">
            <h1 className="mb-5">Enter code</h1>
            <input placeholder="12345678" type="text" className="form-control form-control-lg rounded-4 mb-4"/>
            <button className="btn btn-lg btn-warning rounded-3">Join</button>
        </div>
    </div>
}

export default StudentJoinQuizScreen;
