/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPassageInfo = /* GraphQL */ `
  query GetPassageInfo($id: ID!) {
    getPassageInfo(id: $id) {
      id
      passage
      title
      category
      genre
      keywords
      qc
      details {
        items {
          id
          infoID
          grade
          summary
          freeform
          mainidea
          authorspurpose
          causeandeffect
          problemandsolution
          orderevents
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPassageInfos = /* GraphQL */ `
  query ListPassageInfos(
    $filter: ModelPassageInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPassageInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        passage
        title
        category
        genre
        keywords
        qc
        details {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPassageDetails = /* GraphQL */ `
  query GetPassageDetails($id: ID!) {
    getPassageDetails(id: $id) {
      id
      infoID
      grade
      summary
      freeform
      mainidea
      authorspurpose
      causeandeffect
      problemandsolution
      orderevents
      createdAt
      updatedAt
    }
  }
`;
export const listPassageDetails = /* GraphQL */ `
  query ListPassageDetails(
    $filter: ModelPassageDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPassageDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        infoID
        grade
        summary
        freeform
        mainidea
        authorspurpose
        causeandeffect
        problemandsolution
        orderevents
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMatch = /* GraphQL */ `
  query GetMatch($id: ID!) {
    getMatch(id: $id) {
      id
      infoID
      playerCount
      players
      createdAt
      updatedAt
    }
  }
`;
export const listMatches = /* GraphQL */ `
  query ListMatches(
    $filter: ModelMatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMatches(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        infoID
        playerCount
        players
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
