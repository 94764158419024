import TextareaAutosize from 'react-textarea-autosize';
import {Link, useParams} from "react-router-dom";
import useUser from "../../hooks/UseUser";
import API from "@aws-amplify/api";
import {getPassageInfo} from "../../amplify/graphql/queries";
import FormField from "../../components/FormField";
import {deletePassageDetails, deletePassageInfo, updatePassageDetails, updatePassageInfo} from "../../amplify/graphql/mutations";
import {useNavigate} from "react-router";
import AdminNavbar from "./components/AdminNavbar";
import awsExports from "../../aws-exports";
import AdminCheckbox from "./components/AdminCheckbox";
import assertAdmin from "./util/AssertAdmin";
import {useEffect, useRef, useState} from "react";
import ObjectStateWrapper from "../../components/ObjectStateWrapper";
import {Category, Genre} from "../../models";
import useObjectState from "../../hooks/UseObjectState";
import capitalizeWord from "../util/CapitalizeWord";
import AdminDeletableBadge from "./components/AdminDeletableBadge";
import AdminQuestion, {QUESTIONS} from "./components/question/AdminQuestion";

const AdminEditScreen = () => {
    const [id] = useState(useParams().id);
    const navigate = useNavigate();

    const user = useUser();
    assertAdmin(user, navigate);

    const [title, setTitle] = useState("");
    const [category, setCategory] = useState(Category.HISTORY);
    const [genre, setGenre] = useState(Genre.NONFICTION);
    const [details, setDetails] = useState(null);
    const [grade, setGrade] = useState(5);
    const [quality, setQuality] = useState(false);
    const [keywords, setKeywords] = useState([]);

    const [kwd, setKwd] = useState("");
    const kwdRef = useRef();

    useEffect(() => {
        API.graphql({authMode: "API_KEY", authToken: awsExports.aws_appsync_apiKey, query: getPassageInfo, variables: {id: id}}).then(r => {
            const data = r.data.getPassageInfo;
            setTitle(data.title);
            setCategory(data.category);
            setGenre(data.genre);
            setKeywords(data.keywords.split(','));
            const d = {};
            data.details.items.forEach(det => {
                const dd = {...det};
                delete dd['createdAt'];
                delete dd['updatedAt'];
                delete dd['infoID'];
                QUESTIONS.forEach(q => {
                    dd[q] = JSON.parse(det[q]);
                });
                d[det.grade] = dd;
            });
            setDetails(d);
        });
    }, [id]);

    useEffect(() => {
        console.log(keywords);
    }, [keywords]);

    const save = () => {
        const data = {};
        data.id = id;
        data.title = title;
        data.category = category;
        data.genre = genre;
        data.keywords = keywords.join(',');
        data.qc = quality;
        console.log(data);
        API.graphql({authMode: "API_KEY", authToken: awsExports.aws_appsync_apiKey, query: updatePassageInfo, variables: {input: data}
        }).then(r => {
            console.log(r);
        });
        Object.values(details).forEach(dd => {
            dd.infoID = id;
            QUESTIONS.forEach(q => {
                dd[q] = JSON.stringify(dd[q]);
            });
            console.log(dd);
            API.graphql({authMode: "API_KEY", authToken: awsExports.aws_appsync_apiKey, query: updatePassageDetails, variables: {input: dd}
            }).then(r => {
                console.log(r);
            });
        });
    }

    const del = () => {
        API.graphql({authMode: "API_KEY", authToken: awsExports.aws_appsync_apiKey, query: deletePassageInfo, variables: {input: {id: id}}
        }).then(r => {
            console.log(r);
        });
        Object.values(details).forEach(d => {
            API.graphql({authMode: "API_KEY", authToken: awsExports.aws_appsync_apiKey, query: deletePassageDetails, variables: {input: {id: d.id}}
            }).then(r => {
                console.log(r);
            });
        })
        navigate('/admin/library');
    }

    return <>
        <AdminNavbar user={user}/>
        <div className="container">
            <nav className="nav nav-tabs w-100 mb-5 row ms-0">
                <Link className="nav-link active fw-bolder disabled col-1 text-center" to="/admin/edit"><i className="bi bi-pencil-fill"/></Link>
                <Link className="nav-link fw-bolder col-5 text-center" to="/admin/create">Create</Link>
                <Link className="nav-link fw-bolder col-6 text-center" to="/admin/library">Library</Link>
            </nav>

            <div className="mb-4">
                <label className="form-label">Title</label>
                <FormField type="text" className="form-control" getter={title} setter={setTitle}/>
            </div>

            <div className="mb-4">
                <label className="form-label">Category</label>
                <select className="form-select" value={category} onChange={(evt) => {setCategory(evt.target.value)}}>
                    {Object.values(Category).map(cat => <option key={cat} value={cat}>{capitalizeWord(cat.replaceAll("_", " "))}</option>)}
                </select>
            </div>

            <div className="mb-4">
                <label className="form-label">Genre</label>
                <select className="form-select" value={genre} onChange={(evt) => {setGenre(evt.target.value)}}>
                    {Object.values(Genre).map(gre => <option key={gre} value={gre}>{capitalizeWord(gre.replaceAll("_", " "))}</option>)}
                </select>
            </div>

            <div className="mb-5">
                <label className="form-label">Keywords</label>
                <div className="w-100 input d-flex flex-wrap p-2 badge-gap cursor-text" onClick={() => {if (kwdRef && kwdRef.current) {kwdRef.current.focus()}}}>
                    {keywords.map((kw, index) => <ObjectStateWrapper stateArray={[keywords, setKeywords]} index={index} deletable={true} key={index}>
                        <AdminDeletableBadge className="badge rounded-2 bg-light shadow-sm text-dark p-2 d-flex flex-row align-items-center"/>
                    </ObjectStateWrapper>)}
                    <FormField ref={kwdRef} type="text" className="p-0 m-0 b-none" getter={kwd} setter={setKwd} onKeyDown={(evt) => {if (evt.key === ",") {evt.preventDefault(); keywords.push(kwd); setKwd("")}}}/>
                </div>
                <p className="form-text">Press <kbd>,</kbd> to add new</p>
            </div>

            {details && <>
                <div className="mb-5">
                    <label className="form-label">Grade Level</label>
                    <select className="form-select" value={grade} onChange={(evt) => {setGrade(evt.target.value)}}>
                        {Object.keys(details).map(k => <option key={k} value={k}>{k}</option>)}
                    </select>
                </div>

                <ObjectStateWrapper stateArray={[details, setDetails]} index={grade}>
                    <DetailView/>
                </ObjectStateWrapper>
            </>}

            {quality || <AdminCheckbox className="mb-5" label="The quality of this content is acceptable for consumers and does not need further review" state={[quality, setQuality]}/>}

            <div className="d-flex justify-content-between">
                <button className="btn btn-primary" onClick={save}>Save Changes</button>
                {/*<button className="btn btn-primary" onClick={() => {console.log(details)}}>Hi</button>*/}
                <button className="btn btn-danger" onClick={del}><i className="bi bi-trash3-fill"></i> Delete</button>
            </div>
        </div>
    </>
}

const DetailView = ({state}) => {
    const [summary, setSummary] = useObjectState(state, "summary");

    return <>
        <div className="mb-5">
            <label className="form-label">Summarized Text</label>
            <TextareaAutosize minRows={3} className="form-control resize-none overflow-hidden" value={summary} onChange={(evt) => {setSummary(evt.target.value)}}/>
        </div>
        {QUESTIONS.map(v => <AdminQuestion className="mb-5" state={state} index={v} key={v}/>)}
    </>
}

export default AdminEditScreen;
