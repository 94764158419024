import FormField from "../../components/FormField";
import {Link} from "react-router-dom";
import {useNavigate} from "react-router";
import {useState} from "react";
import {Auth} from "aws-amplify";

const AuthRegisterScreen = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [type, setType] = useState(0);

    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const enabled = /^[^@]+@[^.]+\..+$/.test(email) && /.{7,18}/.test(password) && !loading;

    const register = () => {
        setError("");
        setLoading(true);
        Auth.signUp({username: email, password: password, autoSignIn: {enabled: true}, attributes: {"custom:accounttype": type}}).then(result => {
            if (result.userConfirmed) {
                navigate("/");
            } else {
                localStorage.setItem("email", email);
                navigate("/auth/confirm");
            }
        }).catch(e => {
            setError(e.name); //UsernameExistsException
        }).finally(() => {
            setLoading(false);
        });
    }

    return <>
        <div className="triangle"></div>
        <div className="rectangle-sm shadow-lg"></div>
        <div className="rectangle-lg shadow-lg"></div>
        <div className="position-absolute translate-middle start-50 top-50">
            <h1 className="mb-5 ms-1 text-danger user-select-none font-fantasy">Comprehend</h1>
            <div className="p-4 p-lg-5 shadow-lg rounded-3 bg-white">
                <h3 className="mb-5 me-lg-5">Create your Comprehend account</h3>

                <div className="mb-4">
                    <label className="form-label fw-semibold">Account type</label>
                    <select className="form-select" aria-label="Account type" value={type} onChange={event => setType(event.target.value)}>
                        <option value={0}>Parent</option>
                        <option value={1}>Teacher</option>
                    </select>
                </div>

                <div className="mb-4">
                    <label className="form-label fw-semibold">Email</label>
                    <FormField autoFocus={true} type="text" className="form-control" getter={email} setter={setEmail} error={error === "UsernameExistsException" ? "User already exists!" : ""}/>
                </div>

                <div className="mb-5">
                    <label className="form-label fw-semibold">Password</label>
                    <FormField type="password" className="form-control" getter={password} setter={setPassword}/>
                </div>

                <button className="btn btn-primary w-100" onClick={register} disabled={!enabled}>{loading ? <span className="spinner-border spinner-border-sm" role="status"></span> : "Continue"}</button>
            </div>
            <p className="small mt-4">Already have an account? <Link className="text-decoration-none" to="/auth/login">Sign in</Link></p>
        </div>
    </>
}

export default AuthRegisterScreen;
