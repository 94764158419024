import {AICreatePassage} from "../../ai/AI";
import {Link} from "react-router-dom";
import {useNavigate} from "react-router";
import useUser from "../../hooks/UseUser";
import {useState} from "react";
import AdminNavbar from "./components/AdminNavbar";
import TextareaAutosize from "react-textarea-autosize";
import assertAdmin from "./util/AssertAdmin";

const AdminCreateScreen = () => {
    const navigate = useNavigate();

    const user = useUser();
    assertAdmin(user, navigate);

    const [text, setText] = useState("");
    const [loading, setLoading] = useState(false);

    const process_f = () => {
        setLoading(true);
        /*process(text).then(ai => {
            const data = {};
            data.title = ai.title;
            delete ai.title;
            data.category = 0;
            data.qa = JSON.stringify(ai);
            data.search = data.title.toLowerCase();
            data.quality = false;
            console.log(data);
            localStorage.data = JSON.stringify(data);
            API.graphql({authMode: "API_KEY", authToken: awsExports.aws_appsync_apiKey, query: createPassage, variables: {input: data}}).then(r => {
                setLoading(false);
                navigate("/admin/edit/" + r.data.createPassage.id);
            });
            console.log(ai);
        });*/

        /*processNew(text).then(([title, search, data]) => {
            const d = {};
            d.title = title;
            d.category = 0;
            d.qa = JSON.stringify(data);
            d.search = search;
            d.quality = false;
            console.log(d);
            localStorage.data = JSON.stringify(d);
            API.graphql({authMode: "API_KEY", authToken: awsExports.aws_appsync_apiKey, query: createPassage, variables: {input: d}}).then(r => {
                setLoading(false);
                navigate("/admin/edit/" + r.data.createPassage.id);
            });
        });*/
        AICreatePassage(text).then(id => {
            setLoading(false);
            navigate("/admin/edit/" + id);
        });
    }

    // TODO: FIX INPUT AUTO SCALING & FIX DELETED ITEMS SHOWING

    return <>
        <AdminNavbar user={user}/>
        <div className="container">
            <nav className="nav nav-tabs nav-justified w-100 mb-5">
                <Link className="nav-link active fw-bolder" to="/admin/create">Create</Link>
                <Link className="nav-link fw-bolder" to="/admin/library">Library</Link>
            </nav>
            <div className="mb-5">
                <label className="form-label">Input text</label>
                <TextareaAutosize minRows={5} className="form-control w-100 resize-none overflow-hidden" placeholder="John built a house but there was a wolf..." value={text} onChange={(evt) => {
                    setText(evt.target.value)
                }}/>
                <button type="button" className="btn btn-success" onClick={process_f} disabled={loading}>Process</button>
            </div>
        </div>
    </>
}

export default AdminCreateScreen;
