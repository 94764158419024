import {useNavigate} from "react-router";
import {useEffect} from "react";

const AdminHomeScreen = () => {
    const navigate = useNavigate();
    
    useEffect(() => {
        navigate("/admin/library");
    }, [navigate]);

    return <></>
}

export default AdminHomeScreen;
