import {useEffect, useState} from "react";

const useCachedState = (initialState: any, key: string) => {
    const lstate = localStorage.getItem(key);
    const [state, setState] = useState(lstate ? JSON.parse(lstate) : initialState);
    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(state));
    }, [key, state])
    return [state, setState];
}

export default useCachedState;
