import {cloneElement, Dispatch, SetStateAction} from "react";

const Hoverable = ({setter, children}: { setter: Dispatch<SetStateAction<boolean>>, children: * }) => {
    const props = {};
    const onMouseOver = children.props.onMouseOver || (() => {});
    const onMouseOut = children.props.onMouseOut || (() => {});
    props['onMouseOver'] = () => {
        setter(true);
        onMouseOver();
    };
    props['onMouseOut'] = () => {
        setter(false);
        onMouseOut();
    }
    return cloneElement(children, props);
}

export default Hoverable;
