import AdminEditableText from "../AdminEditableText";
import ObjectStateWrapper from "../../../../components/ObjectStateWrapper";
import {ReactSortable} from "react-sortablejs";
import {StaticOrderChoicesQuestion} from "../../../../Question.ts";

const AdminStaticOrderChoicesQuestion = ({state}) => {
    const [s: StaticOrderChoicesQuestion, setS] = state;

    return <ReactSortable list={s} setList={setS} animation={150} ghostClass="border-success">
        {s.map((item, index) => (
            <div className="w-100 shadow-sm rounded-3 mb-2 p-2 border border-danger pointer" key={item}>
                <ObjectStateWrapper stateArray={state} index={index}>
                    <AdminEditableText tr={(x) => <p className="mb-0">{x}</p>}/>
                </ObjectStateWrapper>
            </div>
        ))}
    </ReactSortable>
}

export default AdminStaticOrderChoicesQuestion;
