import {useNavigate} from "react-router";
import {Auth} from "aws-amplify";

const AuthLogoutScreen = () => {
    const navigate = useNavigate();
    Auth.signOut({global: false}).finally(() => {
        navigate("/");
    })
    return <></>
}

export default AuthLogoutScreen;
