import {Link} from "react-router-dom";

const Navbar = ({id, title, children, className = "", secondaryTitle = ""}) => {
    const toggler = `navbarToggler${id}`;

    return <nav className={`navbar navbar-expand-lg navbar-light bg-light ${className}`} id={id}>
        <div className="container">
            <Link className="navbar-brand text-danger" to="/">{title}{secondaryTitle && <span className="text-primary"> {secondaryTitle}</span>}</Link>
            {children && <button className="navbar-toggler b-none shadow-none" type="button" data-bs-toggle="collapse" data-bs-target={`#${toggler}`} aria-controls={toggler} aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>}

            <div className="collapse navbar-collapse" id={toggler}>
                <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
                    {children && (children.map ? children.map((child, index) => <li key={index} className="nav-item">{child}</li>) : <li className="nav-item">{children}</li>)}
                </ul>
            </div>
        </div>
    </nav>
}

export default Navbar;
