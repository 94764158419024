import Navbar from "../../components/Navbar";
import {useLocation} from "react-router";

const ErrorPageNotFoundScreen = () => {
    const location = useLocation();

    return <>
        <Navbar id={"homeNavbar"} title={"Comprehend"} className={"mb-5"} secondaryTitle={"Studio"}/>
        <div className="position-absolute top-50 start-50 translate-middle">
            <div className="d-flex justify-content-center">
                <i className="bi bi-exclamation-triangle font-xxl text-info"/>
            </div>
            <h2>Sorry, that page was not found on Comprehend Studio</h2>
            <p className="lead">Page: {location.pathname}</p>
        </div>
    </>
}

export default ErrorPageNotFoundScreen;
