import {Link} from "react-router-dom";
import Navbar from "../../../components/Navbar";

const AdminNavbar = ({user}) => {
    return <Navbar id={"headerNav"} title={"Comprehend"} className={"mb-1"} secondaryTitle={"Admin"}>
        <Link className="nav-link" to={user ? "/auth/logout" : "/auth/login"}>{user ? "Logout": "Login"}</Link>
    </Navbar>
}

export default AdminNavbar;
