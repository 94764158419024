import AdminEditableText from "../AdminEditableText";
import AdminEditableAnswerSelect from "../AdminEditableAnswerSelect";
import useObjectState from "../../../../hooks/UseObjectState";
import ObjectStateWrapper from "../../../../components/ObjectStateWrapper";

const AdminDynamicMultipleChoiceQuestion = ({state, className}) => {
    const question = useObjectState(state, "question");
    const correct = useObjectState(state, "correct");
    const choices = useObjectState(state, "choices");

    return <div className={className}>
        <AdminEditableText state={question} tr={(t) => <h6>{t}</h6>}/>
        <AdminEditableAnswerSelect className="ms-5" correct={correct} choices={choices}/>
    </div>
}

const AdminDynamicMultipleChoiceQuestionList = ({state}) => {
    return state[0].map((_, index) => <ObjectStateWrapper stateArray={state} index={index} key={index}>
        <AdminDynamicMultipleChoiceQuestion className="ms-4 mb-4"/>
    </ObjectStateWrapper>)
}

export default AdminDynamicMultipleChoiceQuestion;
export {AdminDynamicMultipleChoiceQuestionList};
