const AssertAccountType = (user, type: number, navigate) => {
    if (user) {
        if (!user.attributes["custom:accounttype"]) {
            navigate("/admin");
            return
        }
        if (Number.parseInt(user.attributes["custom:accounttype"]) !== type) {
            navigate("/");
        }
    }
}

export default AssertAccountType;
