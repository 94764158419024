import './css/bootstrap_journal.css';
import 'animate.css/animate.css';
import './css/App.css';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import HomeScreen from "./pages/home/HomeScreen";
import {Amplify} from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import awsconfig from './aws-exports';
import AuthLogoutScreen from "./pages/auth/AuthLogoutScreen";
import AdminEditScreen from "./pages/admin/AdminEditScreen";
import AdminLibraryScreen from "./pages/admin/AdminLibraryScreen";
import AdminCreateScreen from "./pages/admin/AdminCreateScreen";
import StudentJoinQuizScreen from "./pages/student/StudentJoinQuizScreen";
import AdminHomeScreen from "./pages/admin/AdminHomeScreen";
import StudentHomeScreen from "./pages/student/StudentHomeScreen";
import ParentHomeScreen from "./pages/parent/ParentHomeScreen";
import AuthLoginScreen from "./pages/auth/AuthLoginScreen";
import AuthRegisterScreen from "./pages/auth/AuthRegisterScreen";
import AuthConfirmScreen from "./pages/auth/AuthConfirmScreen";
import TeacherHomeScreen from "./pages/teacher/TeacherHomeScreen";
import HomeTryScreen from "./pages/home/HomeTryScreen";
import AuthScreen from "./pages/auth/AuthScreen";
import ErrorPageNotFoundScreen from "./pages/error/ErrorPageNotFoundScreen";

Amplify.configure(awsconfig);

const App = () => {
    const router = createBrowserRouter([
        {
            path: "/",
            element: <HomeScreen/>
        },
        {
            path: "/auth",
            element: <AuthScreen/>
        },
        {
            path: "/auth/login",
            element: <AuthLoginScreen/>
        },
        {
            path: "/auth/register",
            element: <AuthRegisterScreen/>
        },
        {
            path: "/auth/confirm",
            element: <AuthConfirmScreen/>
        },
        {
            path: "/auth/logout",
            element: <AuthLogoutScreen/>
        },
        {
            path: "/admin",
            element: <AdminHomeScreen/>
        },
        {
            path: "/admin/edit/:id",
            element: <AdminEditScreen/>
        },
        {
            path: "/admin/create",
            element: <AdminCreateScreen/>
        },
        {
            path: "/admin/library",
            element: <AdminLibraryScreen/>
        },
        {
            path: "/student",
            element: <StudentHomeScreen/>
        },
        {
            path: "/student/join",
            element: <StudentJoinQuizScreen/>
        },
        {
            path: "/parent",
            element: <ParentHomeScreen/>
        },
        {
            path: "/teacher",
            element: <TeacherHomeScreen/>
        },
        {
            path: "/try",
            element: <HomeTryScreen/>
        },
        {
            path: "*",
            element: <ErrorPageNotFoundScreen/>
        },
    ])

    return <RouterProvider router={router}/>
}

export default App;
