const assertAdmin = (user, navigate) => {
    if (user === false) {
        navigate("/");
        return;
    }

    if (user) {
        if (!user.signInUserSession.accessToken.payload['cognito:groups']) {
            navigate("/");
            return;
        }
        if (!user.signInUserSession.accessToken.payload['cognito:groups'].includes("Admin")) {
            navigate("/");
        }
    }
}

export default assertAdmin;
