const HomeAnswerSelect = ({className, choices, selected, reviewing = false, correct = undefined}) => {
    const select = (index) => {
        if (!reviewing) {
            selected[1](index);
        }
    }

    return <div className={className}>
        {choices.map((choice, index) => <div className="form-check" key={index}>
            <input className="form-check-input pointer" type="radio" checked={index === selected[0] || (reviewing && index === correct)} onChange={() => {select(index)}} disabled={reviewing && correct !== index}/>
            <label className="form-check-label pointer" onClick={() => {select(index)}}>{choice}</label>
        </div>)}
    </div>
}

export default HomeAnswerSelect;
