import {StaticMatchChoicesQuestion} from "../../../../Question.ts";
import {ReactSortable} from "react-sortablejs";
import ObjectStateWrapper from "../../../../components/ObjectStateWrapper";
import AdminEditableText from "../AdminEditableText";
import useObjectState from "../../../../hooks/UseObjectState";

const AdminStaticMatchChoicesQuestion = ({state}) => {
    const [choices, setChoices] = useObjectState(state, "choices");
    const [matches, setMatches] = useObjectState(state, "matches");

    return <>
        <div className="row">
            <div className="col-6">
                {choices.map((item, index) => (
                    <div className="w-100 shadow-sm rounded-3 mb-2 p-3 border border-danger" key={item}>
                        <ObjectStateWrapper stateArray={[choices, setChoices]} index={index}>
                            <AdminEditableText tr={(x) => <p className="mb-0">{x}</p>}/>
                        </ObjectStateWrapper>
                    </div>
                ))}
            </div>
            <div className="col-1">
                {choices.map((_, index) => (
                    <div className="d-flex justify-content-center align-items-center" key={index}>
                        <i className="bi bi-arrow-right"></i>
                    </div>
                ))}
            </div>
            <div className="col-5">
                <ReactSortable list={matches} setList={setMatches} animation={150}>
                    {matches.map((item, index) => (
                        <div className="w-100 shadow-sm rounded-3 mb-2 p-3 border border-danger pointer" key={item}>
                            <ObjectStateWrapper stateArray={[matches, setMatches]} index={index}>
                                <AdminEditableText tr={(x) => <p className="mb-0">{x}</p>}/>
                            </ObjectStateWrapper>
                        </div>
                    ))}
                </ReactSortable>
            </div>
        </div>
    </>
}

export default AdminStaticMatchChoicesQuestion;
