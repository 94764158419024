import AdminEditableAnswerSelect from "../AdminEditableAnswerSelect";
import useObjectState from "../../../../hooks/UseObjectState";

const AdminStaticMultipleChoiceQuestion = ({state}) => {
    const correct = useObjectState(state, "correct");
    const choices = useObjectState(state, "choices");

    return <AdminEditableAnswerSelect className="ms-5" correct={correct} choices={choices}/>
}

export default AdminStaticMultipleChoiceQuestion;
