import useUser from "../../hooks/UseUser";
import {useNavigate} from "react-router";
import Navbar from "../../components/Navbar";
import {useState} from "react";
import {Category, Genre} from "../../models";
import capitalizeWord from "../util/CapitalizeWord";
import useMultiple from "../../hooks/useMultiple";

const HomeScreen = () => {
    const navigate = useNavigate();

    const user = useUser();

    const [grade, setGrade] = useState(-1);

    const [category, setCategory] = useState(Category.HISTORY);
    const [genre, setGenre] = useState(Genre.NONFICTION);

    useMultiple("#testhaha", "linear-gradient(#17082e 0%, #1a0933 7%, #1a0933 80%, #0c1f4c 100%)", undefined, 0.8);

    const go = () => {
        navigate("/try", {state: {grade: grade, category: category, genre: genre}});
    }

    return <>
        <Navbar id={"homeNavbar"} title={"Comprehend"} className={"mb-0 shadow-sm"} secondaryTitle={"Studio"}/>
        <div className="home-intro">
            <div className="container p-1">
                <h1 className="text-center my-5">Welcome to <span className="text-danger user-select-none font-fantasy">Comprehend</span> <span className="text-primary user-select-none font-fantasy">Studio</span></h1>
                <p className="text-start mx-3 mb-5"><strong>What is Comprehend?</strong> Comprehend is a free-to-use website that allows your student to study English. Comprehend supports grades one through five and has a vast selection of passages for your student. Whether you are studying for a test or practicing English, Comprehend is great free tool for you.</p>
                <div className="d-md-flex justify-content-center gap-5 mb-5">
                    <div className="bg-light rounded-3 p-3 text-center shadow-sm hover-grow">
                        <h5 className="mb-4 fw-normal">Comprehend is always <strong>100% free</strong>!</h5>
                        <p className="text-start px-3">Comprehend was created with the goal of allowing any student to practice English without needing to pay.</p>
                    </div>
                    <div className="bg-light rounded-3 p-3 text-center shadow-sm hover-grow">
                        <h5 className="mb-4 fw-normal">Comprehend has <strong>over 10,000</strong> passages!</h5>
                        <p className="text-start px-3">Comprehend has many categories and genres of passages, to keep your student engaged on their reading.</p>
                    </div>
                    <div className="bg-light rounded-3 p-3 text-center shadow-sm hover-grow">
                        <h5 className="mb-4 fw-normal">Comprehend is <strong>fun</strong>!</h5>
                        <p className="text-start px-3">Comprehend allows your student to practice in a game-like manner, and compete with other students.</p>
                    </div>
                </div>
                <div className="d-sm-flex justify-content-center gap-5 mb-5">
                    <div className="bg-light rounded-3 p-3 shadow-sm hover-grow">
                        <blockquote className="blockquote mb-0">
                            <p>This was exactly what my child needed for passing ...some test...</p>
                            <footer className="blockquote-footer text-center">John Doe (Parent)</footer>
                        </blockquote>
                    </div>
                    <div className="bg-light rounded-3 p-3 shadow-sm hover-grow">
                        <blockquote className="blockquote mb-0">
                            <p>This is a great tool for letting my students practice English in a fun way!</p>
                            <footer className="blockquote-footer text-center">Jane Doe (Teacher)</footer>
                        </blockquote>
                    </div>
                </div>
            </div>
        </div>
        <div className="home-try">
            <div className="container p-1">
                <div className="rounded-2 d-flex justify-content-center align-items-center py-3 home-bar shadow-sm my-5">
                    <h4 className="m-0">Try it now!</h4>
                </div>
                <p className="form-text">Select your grade level</p>
                <div className="d-flex w-100 justify-content-between mb-5">
                    <button className={"btn btn-lg " + (grade === 1 ? "btn-success" : "btn-info")} onClick={() => {grade === 1 ? setGrade(-1) : setGrade(1)}}>Grade 1</button>
                    <button className={"btn btn-lg " + (grade === 2 ? "btn-success" : "btn-info")} onClick={() => {grade === 2 ? setGrade(-1) : setGrade(2)}}>Grade 2</button>
                    <button className={"btn btn-lg " + (grade === 3 ? "btn-success" : "btn-info")} onClick={() => {grade === 3 ? setGrade(-1) : setGrade(3)}}>Grade 3</button>
                    <button className={"btn btn-lg " + (grade === 4 ? "btn-success" : "btn-info")} onClick={() => {grade === 4 ? setGrade(-1) : setGrade(4)}}>Grade 4</button>
                    <button className={"btn btn-lg " + (grade === 5 ? "btn-success" : "btn-info")} onClick={() => {grade === 5 ? setGrade(-1) : setGrade(5)}}>Grade 5</button>
                </div>
                <p className="form-text">Select a category{/* and genre*/}</p>
                <div className="input-group mb-5">
                    <select className="form-select form-select-lg" value={category} onChange={(evt) => {setCategory(evt.target.value)}}>
                        {Object.values(Category).map(cat => <option key={cat} value={cat}>{capitalizeWord(cat.replaceAll("_", " "))}</option>)}
                    </select>

                    {/*<select className="form-select form-select-lg" value={genre} onChange={(evt) => {setGenre(evt.target.value)}}>
                            {Object.values(Genre).map(gre => <option key={gre} value={gre}>{capitalizeWord(gre.replaceAll("_", " "))}</option>)}
                        </select>*/}
                </div>
                <div className="d-flex w-100 justify-content-center mb-5">
                    <button className="btn btn-success btn-lg shadow px-5 hover-grow" onClick={go} disabled={grade < 1}>Go >>></button>
                </div>
            </div>
        </div>
        <div className="home-teacher">
            <div className="container p-1">
                <div className="rounded-2 d-flex justify-content-center align-items-center py-3 home-bar shadow-sm my-5">
                    <h4 className="m-0">Comprehend for teachers</h4>
                </div>
                <p className="mx-3 mb-5 text-light"><strong>Why should I use Comprehend in my school?</strong> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec nunc lorem, suscipit at iaculis sit amet, congue sit amet dui. Cras diam lorem, elementum vitae mattis vel, pellentesque eget ex. Integer vitae consequat risus. Maecenas nec molestie urna. Vivamus tincidunt, turpis eu convallis facilisis, leo leo aliquet nulla, a porttitor felis urna eget libero. Proin diam nisl, dictum et mollis suscipit, elementum et diam. Curabitur ullamcorper ornare enim, luctus lobortis justo laoreet id. Etiam condimentum quam id enim commodo viverra. Proin sapien leo, laoreet ac augue ut, bibendum accumsan orci. Nullam ut felis sapien. Ut nec hendrerit magna, sit amet cursus nisl. Nulla id massa in mauris imperdiet euismod. Mauris sagittis, nisi in tincidunt placerat, diam massa maximus turpis, vel suscipit odio urna tincidunt risus. Praesent eget molestie felis. Fusce egestas porta odio sit amet euismod. Fusce quis elit ante. Integer euismod lacus tempor, scelerisque velit ac, rutrum nisl. Praesent et vehicula quam. Cras non magna quis mauris accumsan gravida. Integer tempor pharetra felis, sit amet consectetur nulla aliquam in. Vestibulum luctus eu massa a ultrices. Maecenas eleifend mauris id malesuada aliquet. Nulla accumsan hendrerit mattis. Phasellus in libero imperdiet, pellentesque lorem ut, suscipit nunc. Curabitur fermentum augue urna, at euismod metus fermentum a. Nunc venenatis lorem ut nisi fermentum ultricies. Maecenas consequat eu magna nec porta. Mauris tempus ultrices ligula, quis molestie eros lobortis condimentum. Integer scelerisque sem vel leo sagittis, eget consectetur quam vestibulum. Proin vestibulum sem et placerat tempus. Maecenas nunc felis, porttitor vitae dui bibendum, blandit iaculis orci. Vestibulum mollis nulla nunc, sit amet semper dui efficitur id. Sed malesuada mauris eget mauris pretium tincidunt.</p>
                <div className="bg-light rounded-3 p-3 shadow-sm mb-5 mx-3 b-none hover-grow" id="testhaha">
                    <blockquote className="blockquote mb-0">
                        <p className="text-warning">Instead of assigning typical reading packets as homework for my second-grade class, I assign five Comprehend passages. My students seem to learn better this way.</p>
                        <footer className="blockquote-footer text-center text-light">Johnny Doe (Teacher)</footer>
                    </blockquote>
                </div>
            </div>
        </div>
        <div className="home-footer">
            <div className="container p-1 d-flex justify-content-center">
                <p className="form-text my-3"><i className="bi bi-c-circle"/> <span className="font-fantasy text-danger">Comprehend</span> <span className="font-fantasy text-primary">Studio</span> 2022. All rights reserved.</p>
            </div>
        </div>
    </>
}

export default HomeScreen;
