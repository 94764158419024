const execute = async (prompt: string, tokens: number) => {
    const response = await (await fetch("https://api.openai.com/v1/completions", {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + "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",
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({
            model: "text-davinci-003",
            prompt: prompt,
            temperature: 0.7,
            max_tokens: tokens,
            top_p: 1,
            frequency_penalty: 0,
            presence_penalty: 0
        })
    })).json();
    return JSON.parse((response.choices[0].text[response.choices[0].text.length - 1] === "}" ? "{" : "[") + response.choices[0].text);
}

const AIinfo = async (passage: string) => {
    try {
        return await execute(`Based on the following passage, write a title and a list of keywords.

Boris Pasternak's 1956 novel Doctor Zhivago tells the story of a Russian doctor living at the time of the 1917 Russian Revolution, which led to the establishment of the Soviet Union. Because the book dealt with hardships people faced after the revolution, publishers in the Soviet Union refused to release it, calling it anti-Soviet. Pasternak's solution was to have the book smuggled out of the Soviet Union and published in Europe. Though Soviet authorities tried to prevent it, the book was published in Italy in 1957 and was immediately well received. In 1958, it was translated into English and went on to become one of the most popular novels of the 1950s. =>

{
  "title": "Boris Pasternak's Doctor Zhivago",
  "keywords": "Russian Revolution,Russia,Italy,Boris Pasternak,Soviet Union,Doctor Zhivago,1917"
}

###

Our early ancestors were hunter-gatherers who obtained food by hunting and by searching for edible wild plants. They needed strong bones, including large, strong jaws that enabled them to eat tough, uncooked foods. When our ancestors developed agriculture, however, their diet changed. They began growing plants, such as grains and beans, and raising animals for food; they also started cooking their foods, making them softer and easier to chew. Consequently, the human skeleton underwent radical changes. Over time, the human jaw became smaller and changed shape. Other bones also evolved, becoming lighter, especially in the joints, as a result of both dietary changes and a less active lifestyle. =>

{
  "title": "The Evolution of the Human Skeleton",
  "keywords": "skeleton,evolution,human,diet,jaw,bone,agriculture,hunter-gatherer"
}

###

{VAR_PASSAGE} =>

{
`.replace("{VAR_PASSAGE}", passage), 3000);
    } catch (e) {
        console.log("Error thrown in AIinfo!");
        console.log(e);
    }
}

const AIdetail = async (passage: string, grade: string) => {
    try {
        return await execute(`Summarize the following passage for a {VAR_GRADE}-grader. Provide the output in JSON format.

Boris Pasternak's 1956 novel Doctor Zhivago tells the story of a Russian doctor living at the time of the 1917 Russian Revolution, which led to the establishment of the Soviet Union. Because the book dealt with hardships people faced after the revolution, publishers in the Soviet Union refused to release it, calling it anti-Soviet. Pasternak's solution was to have the book smuggled out of the Soviet Union and published in Europe. Though Soviet authorities tried to prevent it, the book was published in Italy in 1957 and was immediately well received. In 1958, it was translated into English and went on to become one of the most popular novels of the 1950s. =>

{
  "summary": "Boris Pasternak's novel Doctor Zhivago is about a doctor living during the 1917 Russian Revolution, which created the Soviet Union. Since the book talks about how hard life was after the revolution, publishers in the Soviet Union refused to release it. Pasternak had the book smuggled out of the Soviet Union and published in Europe instead. The book was published in Italy in 1957 and was very popular. It was translated into English in 1958 and became one of the most popular novels of the 1950s."
}

###

Our early ancestors were hunter-gatherers who obtained food by hunting and by searching for edible wild plants. They needed strong bones, including large, strong jaws that enabled them to eat tough, uncooked foods. When our ancestors developed agriculture, however, their diet changed. They began growing plants, such as grains and beans, and raising animals for food; they also started cooking their foods, making them softer and easier to chew. Consequently, the human skeleton underwent radical changes. Over time, the human jaw became smaller and changed shape. Other bones also evolved, becoming lighter, especially in the joints, as a result of both dietary changes and a less active lifestyle. =>

{
  "summary": "Our early ancestors were hunter-gatherers who got food by hunting and by finding edible plants in the wild. They needed strong bones, including large, strong jaws that could eat tough, uncooked food. But when our ancestors developed agriculture, their diet changed. They started growing plants, like grains and beans, and raising animals for food. They also started cooking their food, making it softer and easier to chew. So, the human skeleton changed a lot over time. The human jaw became smaller and changed shape. Other bones became lighter, especially in the joints, because of both dietary changes and a less active lifestyle."
}

###

{VAR_PASSAGE} =>

{
`.replace("{VAR_PASSAGE}", passage).replace("{VAR_GRADE}", grade), 3000);
    } catch (e) {
        console.log("Error thrown in AIdetail!");
        console.log(e);
    }
}

const AImainidea = async (passage: string) => {
    try {
        return await execute(`Based on the passage below, write the question, "Which of the following best describes the main theme or lesson of the story?". Provide two answer choices for the question and provide the index of the correct answer choice. Provide the final answer in JSON format.

Callie opened a letter she got in the mail. It was an invitation from the parents of her friend Michael. They were throwing him a surprise birthday party in a banquet hall at the Helmsley, the most expensive hotel in town. Callie wanted to get Michael the perfect gift. At first, her plan was to make him homemade magnets using cut-outs from the superhero comics they both loved. But that wouldn't work anymore. Obviously, a fancy party called for a fancy present.
One day in class, she saw him checking the time, which gave her an idea. A watch! There were lots of expensive watches. It would be the ideal gift.
Callie went to a jewelry store to pick one out. She swallowed hard when she paid for it. The watch cost nearly all the money she'd saved from her part-time job. Even the glossy paper she wrapped it in cost more than regular paper. However, she was relieved that she had gotten an appropriate gift.
The night of the party, Callie invited some of the other guests to hang out at her house beforehand. It was fun to see everyone in formal attire. But something unexpected happened when people started comparing birthday gifts.
Richard had gotten Michael a squirt gun, the kind they had played with as kids. Molly had made Michael a big card. And Jasmine had baked a batch of Michael's favorite cookies.
Suddenly, Callie imagined Michael as he opened the presents. She could picture his warm smile as he unwrapped the squirt gun, the card, and the cookies. Then she pictured the confused look on his face when he saw the expensive watch. Callie recalled the price she had paid, and she blushed.
Luckily, she still had the receipt. She put the wrapped box in her room before leaving with her friends. Her gift would be late, but at least it would be special. =>

{
  "choices": [
    "It's better to give a present that is late than to give an expensive present.",
    "It's better to give a present that is special than to give a present that is expensive."
  ],
  "correct": 1
}

###

Alia was dismayed to find herself in the drawing studio. She had been sick with the flu during the week when everyone else got to pick their classes. She had hoped for Astronomy or Marine Biology, but they were full by the time she got to choose. She loved everything about science, from the way it used facts and formulas to how it revealed the basic nature of things. But art was so . . .un-scientific.
The first class project was self-portraits. Some students were drawing self-portraits using mirrors. Others were working from photographs. Alia glanced at the incomplete sketches, feeling like a cat in a dog show.
The teacher came up to Alia's easel and sat next to her.
"Every portrait begins with a circle," he said. "Then you create a series of lines."
To demonstrate, he drew a group of small, quick portraits. He began each one with a circle, some straight lines, and a triangle to determine where the eyes, nose, and chin should go. Alia had never thought about it, but the features of everyone's face were in the same spots.
Hesitantly, Alia began her own self-portrait. She drew the basic form of a head, the way she had been shown. From there, she used lines to plot the features of her face. The process took patience and precision. She had to take note of each detail. One wrong measurement could throw off the whole portrait.
Alia was surprised by the structure and discipline involved in drawing a portrait. Measuring, studying details, revealing the basic nature of something—it reminded her of what she loved about science. =>

{
  "choices": [
    "Drawing portraits is easy.",
    "Drawing portraits is similar to studying science."
  ],
  "correct": 1
}

###

{VAR_PASSAGE} =>

{
`.replace("{VAR_PASSAGE}", passage), 2900);
    } catch (e) {
        console.log("Error thrown in AImainidea!");
        console.log(e);
    }
}

const AIauthorspurpose = async (passage: string) => {
    try {
        return await execute(`Based on the following text, write a "Which author's purpose is suggested by the text?" question. Provide two answer choices and provide the index of the correct one.

While still on the boat, I read about Hawaiian green sea turtles: their large carapace, or top shell; their long life span; and their plant diet. Later, when I dropped into the water to snorkel, I was reminded how startlingly different study and experience can be. Once I entered the water, I saw its shadow in the distance. It was a big one, maybe four feet long, waving its winglike limbs slowly through the blue. It moved gently in my direction, head outstretched, gliding with the swaying motion of the surf and dipping to graze on the seagrass-lined rocks below. Its giant carapace was suddenly washed in sunlight—an oval of symmetrical plates, each patterned in swirling yellow-gold. I floated above at the surface, hoping not to disturb the turtle, the low pulse of waves in my ears. =>

{
  "choices": [
    "To persuade the reader that green sea turtles are gentle creatures",
    "To provide information about Hawaiian green sea turtles"
  ],
  "correct": 1
}

###

{VAR_PASSAGE} =>

{
`.replace("{VAR_PASSAGE}", passage), 3400);
    } catch (e) {
        console.log("Error thrown in AIauthorspurpose!");
        console.log(e);
    }
}

const AIcauseandeffect = async (passage: string) => {
    try {
        return await execute(`Based on the following passage, write two cause and effect pairs.

In curling, players use brooms to maneuver a giant stone to the center of a bull's-eye. Historically, the brooms were made from natural fibers, but that has changed in recent years. A broom made with artificial materials, dubbed the "Frankenbroom," gave players more control over the stone—too much control, according to fifty of the top curling teams. As a result of the controversy, Frankenbrooms were banned in 2015. However, other brooms have also raised concerns. For this reason, Canada's World Curling Federation (WCF) and the National Research Council of Canada (NRC) joined forces to measure how different brooms affect the stone and the ice. =>

[
  {
    "cause": "Frankenbrooms caused controversy",
    "effect": "Officials banned the brooms"
  },
  {
    "cause": "Other brooms were questioned",
    "effect": "The WCF and NRC tested the brooms"
  }
]

###

Since 2013, the population of sea stars along North America's Pacific Coast has been dramatically reduced. Microbiologist Ian Hewson believes a type of virus known as Sea Star Association Densovirus (SSaDV) is responsible. Due to the disease, sea stars eventually decompose into a white sludge. Surprisingly, though, hundreds of sea star babies appeared in the spring of 2016 along the Oregon coast. Researchers think that the massive reduction in adult sea stars has contributed to the baby boom. Fewer hungry adult sea stars means more food for hungry babies. In turn, more food has led to a greater survival rate for the youngsters. =>

[
  {
    "cause": "The population of sea stars has been dramatically reduced",
    "effect": "The baby boom of sea stars"
  },
  {
    "cause": "Massive reduction in adult sea stars",
    "effect": "More food for hungry babies"
  }
]

###

{VAR_PASSAGE} =>

[
`.replace("{VAR_PASSAGE}", passage), 3400);
    } catch (e) {
        console.log("Error thrown in AIcauseandeffect!");
        console.log(e);
    }
}

const AIproblemandsolution = async (passage: string) => {
    try {
        return await execute(`Based on the following passage, write two problem and solution pairs.

Like many other countries, Germany produces a huge amount of waste. The German nonprofit group Innatura is trying to change that. Companies often burn perfectly good products simply because promotional offers have ended or packaging has changed. Innatura saves these products from being destroyed. Instead, the group redistributes the products to charities. Food waste is also a big issue in Germany: more than eighteen million metric tons of food are tossed every year. In Berlin, some residents make sure good food doesn't go in the trash. They leave their extras at drop-off points and let others know what's available online. =>

[
  {
    "problem": "Usable products are burned",
    "solution": "Innatura gives them to charities"
  },
  {
    "problem": "Edible foods are thrown away",
    "solution": "Berlin residents share them"
  }
]

###

DNA testing is an important tool for identifying and tracking endangered species. For instance, scientists can use DNA to check that fish served in restaurants is correctly labeled. Sometimes, restaurants mistakenly serve endangered fish—periodic DNA tests can help prevent this. But what if the animal in question is still alive? The answer may be eDNA, or environmental DNA, which can be taken from the soil or water in which an animal lives without disturbing the animal itself. Gathering eDNA is less stressful to animals than taking DNA samples, since scientists can simply take samples of water from its environment. =>

[
  {
    "problem": "Identifying endangered animals is difficult",
    "solution": "DNA testing"
  },
  {
    "problem": "Taking DNA samples from animals is stressful",
    "solution": "Gathering eDNA"
  }
]

###

{VAR_PASSAGE} =>

[
`.replace("{VAR_PASSAGE}", passage), 3400);
    } catch (e) {
        console.log("Error thrown in AIproblemandsolution!");
        console.log(e);
    }
}

const AIorderevents = async (passage: string) => {
    try {
        return await execute(`Based on the following passage, write five key events in order.

Boris Pasternak's 1956 novel Doctor Zhivago tells the story of a Russian doctor living at the time of the 1917 Russian Revolution, which led to the establishment of the Soviet Union. Because the book dealt with hardships people faced after the revolution, publishers in the Soviet Union refused to release it, calling it anti-Soviet. Pasternak's solution was to have the book smuggled out of the Soviet Union and published in Europe. Though Soviet authorities tried to prevent it, the book was published in Italy in 1957 and was immediately well received. In 1958, it was translated into English and went on to become one of the most popular novels of the 1950s. =>

[
  "Boris Pasternak writes Doctor Zhivago.",
  "Soviet Union publishers refuse to release the book, calling it anti-Soviet.",
  "Pasternak has the book smuggled out of the Soviet Union.",
  "The book is published in Italy in 1957 and is well received.",
  "The book is translated into English in 1958 and becomes one of the most popular novels of the 1950s."
]

###

{VAR_PASSAGE} =>

[
`.replace("{VAR_PASSAGE}", passage), 3300);
    } catch (e) {
        console.log("Error thrown in AIorderevents!");
        console.log(e);
    }
}

const AIgeneralquestions = async (passage: string, grade: string) => {
    try {
        return await execute(`Write 5 questions based on the text for a {VAR_GRADE}-grader. Provide four lettered answer choices for each question. Include the correct answer for each question. The final response should be in JSON.

Boris Pasternak's 1956 novel Doctor Zhivago tells the story of a Russian doctor living at the time of the 1917 Russian Revolution, which led to the establishment of the Soviet Union. Because the book dealt with hardships people faced after the revolution, publishers in the Soviet Union refused to release it, calling it anti-Soviet. Pasternak's solution was to have the book smuggled out of the Soviet Union and published in Europe. Though Soviet authorities tried to prevent it, the book was published in Italy in 1957 and was immediately well received. In 1958, it was translated into English and went on to become one of the most popular novels of the 1950s. =>

[
  {
    "question": "Who is the author of Doctor Zhivago?",
    "choices": [
      "J.D. Salinger",
      "Boris Pasternak",
      "James Baldwin",
      "Toni Morrison"
    ],
    "correct": 1
  },
  {
    "question": "What is the book about?",
    "choices": [
      "A doctor living in the Soviet Union during the 1917 Russian Revolution",
      "A book that was published in Europe because the Soviet Union refused to release it",
      "A book that became one of the most popular novels of the 1950s",
      "A book that was translated into English in 1958"
    ],
    "correct": 0
  },
  {
    "question": "Why was the book not published in the Soviet Union?",
    "choices": [
      "Because the book was about the 1917 Russian Revolution",
      "Because the book was published in Europe",
      "Because the book was smuggled out of the Soviet Union",
      "Because the book was anti-Soviet"
    ],
    "correct": 3
  },
  {
    "question": "How did the book become published in Europe?",
    "choices": [
      "It was published in Italy in 1957",
      "It was translated into English in 1958",
      "It was smuggled out of the Soviet Union",
      "It was published in the Soviet Union in 1956"
    ],
    "correct": 2
  },
  {
    "question": "What year was the book published in the Soviet Union?",
    "choices": [
      "1957",
      "1958",
      "1956",
      "1959"
    ],
    "correct": 2
  }
]

###

{VAR_PASSAGE} =>

[
`.replace("{VAR_PASSAGE}", passage).replace("{VAR_GRADE}", grade), 2400);
    } catch (e) {
        console.log("Error thrown in AIgeneralquestions!");
        console.log(e);
    }
}

export { AIinfo, AIdetail, AImainidea, AIauthorspurpose, AIcauseandeffect, AIproblemandsolution, AIorderevents, AIgeneralquestions };
