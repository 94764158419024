import {useLocation, useNavigate} from "react-router";
import {useEffect, useState} from "react";
import Navbar from "../../components/Navbar";
import {useStopwatch} from "react-timer-hook";
import {QUESTIONS, TITLES} from "../admin/components/question/AdminQuestion";
import ObjectStateWrapper from "../../components/ObjectStateWrapper";
import HomeQuestion from "./components/question/HomeQuestion";
import API from "@aws-amplify/api";
import awsExports from "../../aws-exports";
import {listPassageDetails, listPassageInfos} from "../../amplify/graphql/queries";
import {Category} from "../../models";
import ReactConfetti from "react-confetti";

const formatTime = (stopwatch) => {
    let seconds = stopwatch.seconds.toString();
    let minutes = stopwatch.minutes.toString();
    let hours = stopwatch.hours.toString();

    seconds = seconds.length === 1 ? "0" + seconds : seconds;
    minutes = minutes.length === 1 ? "0" + minutes : minutes;
    hours = hours.length === 1 ? "0" + hours : hours;

    return hours + ":" + minutes + ":" + seconds
}

const processQuestion = (question, id) => {
    if (!question['question']) {
        question.question = TITLES[id];
    }
    return question;
}

const POINTS_MULTIPLIER = 10;
const TIME_FACTOR = 4;

const calculatePoints = (totalQuestions: number, correctQuestions: number, time: number) => {
    let points = correctQuestions * POINTS_MULTIPLIER;
    points -= time / TIME_FACTOR;
    return Math.ceil(points);
}

const HomeTryScreen = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {grade, category} = location.state || {grade: 5, category: Category.HISTORY};

    const [data, setData] = useState(null);
    const [info, setInfo] = useState(null);
    const [detail, setDetail] = useState(null);

    const stopwatch = useStopwatch({autoStart: false});

    const [questions, setQuestions] = useState(null);
    const [correct, setCorrect] = useState(null);
    const [ids, setIds] = useState(null);

    const [currentQuestion, setCurrentQuestion] = useState(0);

    const [reviewing, setReviewing] = useState(false);
    const [done, setDone] = useState(false);

    const [stats, setStats] = useState(null);
    const [totalPoints, setTotalPoints] = useState(0);

    const loadData = () => {
        setReviewing(false);
        setData(null);
        const infofilter = {
            category: {
                eq: category
            }
        };
        API.graphql({authMode: "API_KEY", authToken: awsExports.aws_appsync_apiKey, query: listPassageInfos, variables: {filter: infofilter, limit: 1}}).then(r => {
            console.log(r);
            const detailfilter = {
                infoID: {
                    eq: r.data.listPassageInfos.items[0].id
                },
                grade: {
                    eq: grade
                }
            };
            API.graphql({authMode: "API_KEY", authToken: awsExports.aws_appsync_apiKey, query: listPassageDetails, variables: {filter: detailfilter}}).then(rr => {
                console.log(rr);
                setData({info: r.data.listPassageInfos.items[0], detail: rr.data.listPassageDetails.items[0]});
            });
        });
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (!data) {
            setInfo(null);
            setDetail(null);
            setQuestions(null);
            setCorrect(null);
            setStats(null);
            return;
        }

        setInfo(data.info);

        const qq = {};
        const c = {};
        const d = {...data.detail};
        const i = [];
        QUESTIONS.forEach(q => {
            const question = JSON.parse(data.detail[q]);
            d[q] = question;
            if (question.enabled || true) {
                if (q === "freeform") {
                    question.forEach((ffq, index) => {
                        qq[q + index] = processQuestion(ffq, q);
                        c[q + index] = false;
                        i.push(q + index);
                    });
                } else {
                    qq[q] = processQuestion(question, q);
                    c[q] = false;
                    i.push(q);
                }
            }
        });
        setDetail(d);
        setIds(i);
        setQuestions(qq);
        setCorrect(c);
    }, [data]);

    const submit = () => {
        stopwatch.pause();
        setReviewing(true);
        // TODO: Scroll to top
        // TODO: next question, leaderboard

        const corrects = Object.values(correct);
        const s = {};
        s.total = corrects.length;
        s.correct = corrects.filter(x => x === true).length;
        s.time = formatTime(stopwatch);
        s.points = calculatePoints(s.total, s.correct, (stopwatch.hours * 3600) + (stopwatch.minutes * 60) + stopwatch.seconds);
        setTotalPoints(totalPoints + s.points);
        const max = s.total * 7; // Percentage relative to this
        s.score = Math.ceil((s.points / max) * 100);
        setStats(s);
    }

    const next = () => {
        navigate("/");
    }

    return <>
        <Navbar id={"headerNav"} title={"Comprehend"} className={"mb-0"} secondaryTitle={"Studio"}/>
        {<>
            <div className="w-100 bg-light-gray shadow-sm mb-5">
                <div className="container d-flex justify-content-between align-items-center py-2">
                    <p className="m-0">Grade {grade || detail.grade}</p>
                    <p className="m-0">{formatTime(stopwatch)}</p>
                </div>
            </div>
            {!(data && info && detail && questions && correct) ? <div className="container d-flex justify-content-center">
                <div className="spinner-border" role="status" aria-hidden={true}></div>
            </div> : <div className="container p-1">
                {done ? <>
                    <h1>Done!</h1>
                </> : <>
                    <div className="animate__animated animate__bounceInDown mb-5">
                        <h5 className="text-center mb-3">{info.title}</h5>
                        <p className="mx-5">{detail.summary}</p>
                    </div>

                    {/*{reviewing && stats && <div className="border border-primary rounded-3 shadow-sm mx-4 mb-5 p-3">
                    <h4 className="mb-3">Nice!</h4>
                    <h5>{stats.correct}/{stats.total}</h5>
                    <h5>{stats.time}</h5>
                    <h5>{stats.points} points!</h5>
                    <h5>{stats.score}%</h5>
                </div>}*/}

                    {reviewing ? <>
                        <h4 className="mb-4">Review ({stats.correct || 0}/{stats.total || 0})</h4>
                        {ids.map(k => <ObjectStateWrapper stateArray={[correct, setCorrect]} index={k} prop={"correct"} key={k}>
                            <HomeQuestion question={questions[k]} index={k} className="mb-4" reviewing={true}/>
                        </ObjectStateWrapper>)}
                        <div className="d-flex justify-content-end">
                            <button className="btn btn-success" onClick={next}>Continue <i className="bi bi-arrow-right"/></button>
                        </div>
                    </> : <>
                        <ObjectStateWrapper stateArray={[correct, setCorrect]} index={ids[currentQuestion]} prop={"correct"} key={ids[currentQuestion]}>
                            <HomeQuestion question={questions[ids[currentQuestion]]} index={ids[currentQuestion]} className="mb-5 animate__animated animate__slideInRight" reviewing={reviewing}/>
                        </ObjectStateWrapper>

                        <div className="d-flex justify-content-between">
                            <button className="btn btn-success" onClick={() => {setCurrentQuestion(currentQuestion - 1)}} disabled={currentQuestion === 0}><i className="bi bi-arrow-left"/> Previous</button>
                            {currentQuestion === ids.length - 1 ? <button className="btn btn-primary" onClick={submit}>Submit</button> : <button className="btn btn-success" onClick={() => {setCurrentQuestion(currentQuestion + 1)}}>Next <i className="bi bi-arrow-right"/></button>}
                        </div>
                    </>}
                </>}
            </div>}
        </>}
    </>
}

export default HomeTryScreen;
