export const Category = {
  "HISTORY": "history",
  "POLITICS": "politics",
  "SCIENCE": "science",
  "CURRENT_EVENTS": "current_events"
};

export const Genre = {
  "FICTION": "fiction",
  "NONFICTION": "nonfiction",
  "FANTASY": "fantasy"
};
