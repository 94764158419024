import {createElement} from "react";
import HomeMultipleChoiceQuestion from "./HomeMultipleChoiceQuestion";
import HomeOrderChoicesQuestion from "./HomeOrderChoicesQuestion";
import Empty from "../../../../components/Empty";

const RENDERERS = {
    "orderevents": HomeOrderChoicesQuestion,
    "causeandeffect": Empty,
    "problemandsolution": Empty
}

const getRendererForQuestion = (index, question, setCorrect, reviewing) => {
    return createElement(RENDERERS[index] || HomeMultipleChoiceQuestion, {question: question, setCorrect: setCorrect, reviewing: reviewing});
}

const HomeQuestion = ({index, question, correct, className = "", reviewing = false}) => {
    return <div className={className}>
        <div className="d-flex flex-row w-fit-content">
            <h5>{question.question}</h5>
            {reviewing && <span className="ms-3"><i className={"bi bi-" + (correct[0] ? "check-lg text-success" : "x-lg text-primary")}/></span>}
        </div>
        {getRendererForQuestion(index, question, correct[1], reviewing)}
    </div>
}

export default HomeQuestion;
