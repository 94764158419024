import {Link} from "react-router-dom";
import Navbar from "../../components/Navbar";
import useUser from "../../hooks/UseUser";
import {useNavigate} from "react-router";
import assertAccountType from "../util/AssertAccountType";

const ParentHomeScreen = () => {
    const navigate = useNavigate();

    const user = useUser();
    assertAccountType(user, navigate, navigate);

    return <>
        <Navbar id={"headerNav"} title={"Comprehend"} className={"mb-1"} secondaryTitle={"Parent"}>
            <Link className="nav-link" to={user ? "/auth/logout" : "/auth/login"}>{user ? "Logout" : "Login"}</Link>
        </Navbar>
        <div className="container">
            <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addModal">Add student</button>
            <p className="text-muted text-center">You don't have any students yet.</p>
        </div>
    </>
}

export default ParentHomeScreen;
