import {AIauthorspurpose, AIcauseandeffect, AIdetail, AIgeneralquestions, AIinfo, AImainidea, AIorderevents, AIproblemandsolution} from "./prompts.ts";
import API from "@aws-amplify/api";
import awsExports from "../aws-exports";
import {createPassageDetails, createPassageInfo} from "../amplify/graphql/mutations";
import {Genre, Category} from "../models";
import {QUESTIONS} from "../pages/admin/components/question/AdminQuestion";

const processForGrade = async (text: string, grade: string) => {
    const data = {};

    const detail = await AIdetail(text, grade);
    data.summary = detail.summary;

    data.freeform = await AIgeneralquestions(data.summary, grade);

    data.mainidea = await AImainidea(data.summary);

    data.authorspurpose = await AIauthorspurpose(data.summary);

    data.causeandeffect = await AIcauseandeffect(data.summary);
    const a = [];
    const b = [];
    data.causeandeffect.forEach((c) => {
        a.push(c.cause);
        b.push(c.effect);
    });
    data.causeandeffect = {choices: a, matches: b};

    data.problemandsolution = await AIproblemandsolution(data.summary);
    const x = [];
    const y = [];
    data.problemandsolution.forEach((z) => {
        x.push(z.problem);
        y.push(z.solution);
    });
    data.problemandsolution = {choices: x, matches: y};

    data.orderevents = await AIorderevents(data.summary);

    return data;
}

const process = async (text) => {
    try {
        const info = await AIinfo(text);
        const title = info.title;
        const search = title.toLowerCase() + "," + info.keywords.toLowerCase();

        const gradeData = {1: await processForGrade(text, "first"), 3: await processForGrade(text, "third"), 5: await processForGrade(text, "fifth")};

        return [title, search, gradeData];
    } catch (error) {
        throw error;
    }
}

const AICreatePassage = async(text: string) => {
    const [title, search, data] = await process(text);
    const id = (await API.graphql({authMode: "API_KEY", authToken: awsExports.aws_appsync_apiKey, query: createPassageInfo, variables: {input: {title: title, keywords: search, qc: false, genre: Genre.NONFICTION, category: Category.HISTORY, passage: text}}})).data.createPassageInfo.id;
    Object.keys(data).forEach(grade => {
        console.log(grade);
        console.log(data);
        const d = data[grade];
        d.grade = grade;
        d.infoID = id;
        QUESTIONS.forEach(q => {
            d[q].enabled = true;
            d[q] = JSON.stringify(d[q]);
        });
        API.graphql({authMode: "API_KEY", authToken: awsExports.aws_appsync_apiKey, query: createPassageDetails, variables: {input: d}}).then();
    });
    return id;
}

export default process;
export {AICreatePassage};
