import HomeAnswerSelect from "../HomeAnswerSelect";
import {useEffect, useState} from "react";

const HomeMultipleChoiceQuestion = ({question, setCorrect, reviewing}) => {
    const selected = useState(-1);


    useEffect(() => {
        setCorrect(selected[0] === question.correct);
    }, [selected[0]]);
    return <HomeAnswerSelect className="ms-5" choices={question.choices} selected={selected} reviewing={reviewing} correct={question.correct}/>
}

export default HomeMultipleChoiceQuestion;
