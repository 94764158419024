import {useEffect, useState} from "react";
import {Auth} from "aws-amplify";

const useUser = () => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        if (!user) {
            Auth.currentAuthenticatedUser().then(r => {
                setUser(r);
            }).catch(() => {
                setUser(false);
            });
        }
    }, [user]);

    return user;
}

export default useUser;
